import React, { useState, useEffect, useRef } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import AddressInput from './adress';
const FilterComponent = ({ filter, onFilterChange }) => {
  const { hinta, paaKategoria, hiustyyppisi, hiustesiKunto, hiustesiPituus } = filter;

  const [selectedCategory, setSelectedCategory] = useState(paaKategoria || '');
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [showMainCategories, setShowMainCategories] = useState(false);
  const [showHiuksesiSubcategories, setShowHiuksesiSubcategories] = useState(false);
  const [showVarjaysVariOptions, setShowVarjaysVariOptions] = useState(false); // For Värjäys Väri
  const [selectedSukupuoli, setSelectedSukupuoli] = useState(filter.sukupuoli || '');
  const [showSukupuoliDropdown, setShowSukupuoliDropdown] = useState(false);

  const dropdownRef = useRef(null);

  // Options for hiuksesi filters (tyyppijakunto)
  const hiuksesiMap = {
    Hiustyyppi: ['Kiharat','Aaltoilevat', 'Afrot', 'Kiharat'],
    HiustenKunto: ['Normaalit', 'Hauraat', 'Vaikeasti käsiteltävät'],
    HiustenPituus: [ 'Keskipitkät','Extrapitkät', 'Lyhyet','Pitkät'],
    HiustenPaksuus: ['Hienot', 'Keskipaksut', 'Paksut'],
    HiustenVäri: ['Vaalea', 'Keskiruskea', 'Tumma']
  };

  const dropdownRefs = {
    mainCategories: useRef(null),
    subcategories: useRef(null),
    hiuksesi: useRef(null),
    kielet: useRef(null),
    mielenkiinnot: useRef(null),
    sukupuoli: useRef(null),
  };
  
  const [showKieletDropdown, setShowKieletDropdown] = useState(false);
const [selectedKielet, setSelectedKielet] = useState([]); // Array for selected languages
const [kampaajaParturi, setKampaajaParturi] = useState(''); // Track Kampaaja/Parturi selection

const kieletOptions = [
  "suomi", 
  "englanti", 
  "ruotsi", 
  "venäjä", 
  "albania", 
  "arabia", 
  "eesti", 
  "espanja", 
  "kiina", 
  "kurdi", 
  "nepali", 
  "persia", 
  "ranska", 
  "saksa", 
  "serbokroatia", 
  "somali", 
  "thai", 
  "turkki", 
  "urdu", 
  "vietnam"
];

const toggleKieletDropdown = () => {
  setShowKieletDropdown((prev) => !prev);
};

const handleAddressSelect = (locationData) => {
  // Update the filter state with the new location data
  onFilterChange((prevFilter) => ({
    ...prevFilter,
    alue: [locationData]  // Assuming alue needs to be in this format
  }));
};

// Toggle selection of a language
const handleKieliClick = (language) => {
  setSelectedKielet((prevSelected) => {
    let updatedKielet;
    if (prevSelected.includes(language)) {
      updatedKielet = prevSelected.filter(item => item !== language);
    } else {
      updatedKielet = [...prevSelected, language];
    }

    // Update filter with the selected languages
    onFilterChange((prevFilter) => ({
      ...prevFilter,
      kielet: updatedKielet
    }));

    return updatedKielet;
  });
};

// Handle Kampaaja/Parturi selection
const handleKampaajaParturiClick = (role) => {
  setKampaajaParturi((prevRole) => {
    const newRole = prevRole === role ? '' : role; // Toggle off if already selected
    onFilterChange((prevFilter) => ({
      ...prevFilter,
      kampaajaParturi: newRole
    }));
    return newRole;
  });
};


  // Subcategories and options
  const subcategoriesMap = {
    Leikkaukset: ['Energialeikkaus', 'Lasten hiustenleikkaus', 'Mallinmuutosleikkaus', 'Muotoleikkaus','Otsatukan leikkaus', 'Tasausleikkaus'  ],
    Hoidot: ['K18', 'Brazilian Bond Builder', 'Kiiltokäsittely','Niophlex','Olaplex','Wellaplex'],
    Värjäykset: ['Värjäys Väri', 'Raidat', 'Kasviväri','Tyvivärjäys','Vaalennus', 'Balayage','Tyviraidat', 'Moniväri'], // Värjäys Väri as a subcategory
    Kampaukset: ['Hiustenpesu ja föönaus', 'Hääkampaus', 'Juhlakampaus','Kiharakampaus','Lettikampaus','Nutturakampaus','Pikakampaus'],
    RakenneKäsittelyt: ['Brazilian Blowout', 'Kertatiinisuoristus', 'Permanentti'],
    Pidennykset: ['Mago-pidennys','Ompelupidennys','Sinettipidennys','Teippipidennys']
  };
  const [showMielenkiinnotDropdown, setShowMielenkiinnotDropdown] = useState(false);
  const [selectedMielenkiinnot, setSelectedMielenkiinnot] = useState([]); // Array for multiple selections
  
  const mielenkiinnotOptions = [
    "Terveys ja urheilu", "Musiikki", "Elokuvat ja sarjat", "Matkustaminen",
    "Taide ja käsityöt", "Tiede ja teknologia", "Luonto ja retkeily", "Videopelit",
    "Politiikka", "Muoti", "Perhe ja läheiset", "Lukeminen ja kirjoittaminen",
    "Eläimet", "Vieraskulttuurit"
  ];
  
 

  const varjaysVariOptions = ['Tummat', 'Vaaleat', 'Ruskeat', 'Punaiset','Moniväriset']; // Options for Värjäys Väri

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setShowSubcategories(true);
    setShowMainCategories(false);
    setSelectedSubcategories([]);
    setShowVarjaysVariOptions(false); // Ensure color options are hidden
    onFilterChange((prevFilter) => ({
      ...prevFilter,
      paaKategoria: category,
      subKategoria: [],
    }));
  };


  const handleSubcategoryClick = (subcategory, mainCategory) => {
    if (mainCategory === 'Värjäykset' && subcategory === 'Värjäys Väri') {
      setShowVarjaysVariOptions(true); // Show color options instead of setting it as a subcategory
    } else {
      let updatedField;

      switch (mainCategory) {
        case 'Hoidot':
          updatedField = { subHoidot: subcategory };
          break;
        case 'Värjäykset':
          updatedField = { subVarjays: subcategory };
          break;
        case 'Leikkaukset':
          updatedField = { subLeikkaukset: subcategory };
          break;
        case 'Kampaukset':
          updatedField = { subKampaukset: subcategory };
          break;
        case 'RakenneKäsittelyt':
          updatedField = { subKemialliset: subcategory };
          break;
        case 'Pidennykset':
          updatedField = { subPidennykset: subcategory };
          break;
        default:
          updatedField = {};
      }

      onFilterChange((prevFilter) => ({
        ...prevFilter,
        ...updatedField,
        paaKategoria: '',
      }));
    }
  };

  const handleMielenkiintoClick = (interest) => {
    setSelectedMielenkiinnot((prevSelected) => {
      let updatedSelections;
      if (prevSelected.includes(interest)) {
        // Remove interest if already selected
        updatedSelections = prevSelected.filter(item => item !== interest);
      } else {
        // Add interest if not selected
        updatedSelections = [...prevSelected, interest];
      }
      
      // Update the filter with the updated selections immediately
      onFilterChange((prevFilter) => ({
        ...prevFilter,
        mielenkiinnot: updatedSelections
      }));
      
      return updatedSelections;
    });
  };
  

  const toggleMielenkiinnotDropdown = () => {
    setShowMielenkiinnotDropdown(prev => !prev);
  };

  const handleVarjaysVariClick = (color) => {
    onFilterChange((prevFilter) => ({
      ...prevFilter,
      varjaysVari: color, // Set the selected Värjäys Väri in the filter
    }));
  };

  const handleBackToCategories = () => {
    setShowSubcategories(false);
    setShowVarjaysVariOptions(false);
    setShowMainCategories(true);
  };

 
  const handleClickOutside = (event) => {
    Object.values(dropdownRefs).forEach((ref) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (ref === dropdownRefs.mainCategories) setShowMainCategories(false);
        if (ref === dropdownRefs.subcategories) setShowSubcategories(false);
        if (ref === dropdownRefs.hiuksesi) setShowHiuksesiSubcategories(false);
        if (ref === dropdownRefs.kielet) setShowKieletDropdown(false);
        if (ref === dropdownRefs.mielenkiinnot) setShowMielenkiinnotDropdown(false);
        if (ref === dropdownRefs.sukupuoli) setShowSukupuoliDropdown(false);
      }
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const toggleMainCategoryDropdown = () => {
    setShowMainCategories((prevShowMainCategories) => !prevShowMainCategories);
    setShowSubcategories(false);
    setSelectedCategory('');
  };
  const handleSukupuoliClick = (sukupuoli) => {
    setSelectedSukupuoli((prevSukupuoli) => {
      const newSukupuoli = prevSukupuoli === sukupuoli ? '' : sukupuoli;
      onFilterChange((prevFilter) => ({
        ...prevFilter,
        sukupuoli: newSukupuoli,
      }));
      return newSukupuoli;
    });
  };

  const toggleHiuksesiDropdown = () => {
    setShowHiuksesiSubcategories((prev) => !prev);
    setShowMainCategories(false);
  };

  const handleHiuksesiClick = (subcategoryKey, value) => {
    onFilterChange((prevFilter) => ({
      ...prevFilter,
      [subcategoryKey]: value,
    }));
  };

  return (
    <div className="filter-section" ref={dropdownRef}>
      <hr style={{ width: '80%', borderColor: '#ccc', marginBottom: '20px' }} /> {/* Thin line for layout separation */}
      <div className="filter-horizontal-container">
        {/* Main Category Dropdown */}

        {/* Kampaaja/Parturi Selection */}
  <button
    className={`category-button ${kampaajaParturi === 'Kampaaja' ? 'active' : ''}`}
    onClick={() => handleKampaajaParturiClick('Kampaaja')}
  >
    Kampaaja
  </button>
  <button
    className={`category-button ${kampaajaParturi === 'Parturi' ? 'active' : ''}`}
    onClick={() => handleKampaajaParturiClick('Parturi')}
  >
    Parturi
  </button>
  <div className="category-dropdown" ref={dropdownRefs.mainCategories}>
          <button
            className="category-button"
            onClick={toggleMainCategoryDropdown}
          >
            Palvelut
          </button>


          {showMainCategories && (
            <div className="main-category-dropdown">
              {Object.keys(subcategoriesMap).map((category) => (
                <button
                  key={category}
                  className={`main-category-button ${selectedCategory === category ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          )}

          {showSubcategories && selectedCategory && (
            <div className="subcategory-dropdown">
              <button className="back-button" onClick={handleBackToCategories}>
                Back
              </button>
              {subcategoriesMap[selectedCategory].map((subcat) => (
                <button
                  key={subcat}
                  className={`subcategory-button ${selectedSubcategories.includes(subcat) ? 'active' : ''}`}
                  onClick={() => handleSubcategoryClick(subcat, selectedCategory)}
                >
                  {subcat}
                </button>
              ))}
            </div>
          )}

{showVarjaysVariOptions && (
          <div className="subcategory-dropdown">
            <button className="back-button" onClick={handleBackToCategories}>
              Back to Värjäykset
            </button>
            {varjaysVariOptions.map((color) => (
              <button
                key={color}
                className="subcategory-button"
                onClick={() => handleVarjaysVariClick(color)}
              >
                {color}
              </button>
            ))}
          </div>
        )}
        </div>


        <div className="category-dropdown" ref={dropdownRefs.mielenkiinnot}>
  <button className="category-button" onClick={toggleMielenkiinnotDropdown}>
    Mielenkiinnot 
  </button>

  {showMielenkiinnotDropdown && (
    <div className="main-category-dropdown">
      {mielenkiinnotOptions.map((interest) => (
        <button
          key={interest}
          className={`subcategory-button ${selectedMielenkiinnot.includes(interest) ? 'active' : ''}`}
          onClick={() => handleMielenkiintoClick(interest)}
        >
          {interest}
        </button>
      ))}
    </div>
  

  )}
</div>



<div className="category-dropdown" ref={dropdownRefs.kielet}>
  <button className="category-button" onClick={toggleKieletDropdown}>
    Kielet
  </button>

  {showKieletDropdown && (
    <div className="main-category-dropdown">
      {kieletOptions.map((language) => (
        <button
          key={language}
          className={`subcategory-button ${selectedKielet.includes(language) ? 'active' : ''}`}
          onClick={() => handleKieliClick(language)}
        >
          {language}
        </button>
      ))}
    </div>
  )}


</div>
<AddressInput
          value={filter.alue?.[0]?.formatted} // assuming `alue` holds address information
          onSelect={handleAddressSelect}
        />


        {/* Hiuksesi Dropdown */}
        <div className="category-dropdown" ref={dropdownRefs.hiuksesi}>
          <button
            className="category-button"
            onClick={toggleHiuksesiDropdown}
          >
            Hiuksesi
          </button>

          {showHiuksesiSubcategories && (
            <div className="main-category-dropdown">
              {Object.keys(hiuksesiMap).map((hiuksesiCategory) => (
                <div key={hiuksesiCategory}>
                  <label>{hiuksesiCategory}</label>
                  {hiuksesiMap[hiuksesiCategory].map((option) => (
                    <button
                      key={option}
                      className="subcategory-button"
                      onClick={() =>
                        handleHiuksesiClick(
                          hiuksesiCategory === 'Hiustyyppi'
                            ? 'hiustyyppisi'
                            : hiuksesiCategory === 'HiustenKunto'
                            ? 'hiustesiKunto'
                            : 'hiustesiPituus',
                          option
                        )
                      }
                    >
                      {option}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="category-dropdown" ref={dropdownRefs.sukupuoli}>
  <button className="category-button" onClick={() => setShowSukupuoliDropdown(prev => !prev)}>
    Sukupuoli
  </button>

  {showSukupuoliDropdown && (
    <div className="main-category-dropdown">
      {['Mies', 'Nainen', 'Muu'].map((gender) => (
        <button
          key={gender}
          className={`subcategory-button ${selectedSukupuoli === gender ? 'active' : ''}`}
          onClick={() => handleSukupuoliClick(gender)}
        >
          {gender}
        </button>
      ))}
    </div>
  )}
</div>

        {/* Price Filter */}
        <div className="price-filter">
          <label>Hinta {`${hinta[0]}€ - ${hinta[1]}€`}</label>
          <RangeSlider
            min={0}
            max={500}
            step={10}
            defaultValue={hinta}
            onInput={(value) => onFilterChange((prevFilter) => ({ ...prevFilter, hinta: value }))}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
