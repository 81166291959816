// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoadingSpinner.css */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .ball {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
  }
  
  .ball:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .ball:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/loading.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,4BAA4B;IAC9B;EACF","sourcesContent":["/* LoadingSpinner.css */\n.loading-spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  \n  .ball {\n    width: 15px;\n    height: 15px;\n    margin: 0 5px;\n    background-color: #3498db;\n    border-radius: 50%;\n    animation: bounce 0.6s infinite alternate;\n  }\n  \n  .ball:nth-child(2) {\n    animation-delay: 0.2s;\n  }\n  \n  .ball:nth-child(3) {\n    animation-delay: 0.4s;\n  }\n  \n  @keyframes bounce {\n    from {\n      transform: translateY(0);\n    }\n    to {\n      transform: translateY(-20px);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
