import React, { useEffect, useRef } from "react";
import "./BelaPage.css";  // External CSS for styles
import yourVideo from './background.mp4';
import frontImage from './frontImage.webp'
import { useNavigate } from 'react-router-dom';

const BelaPage = () => {


    const videoRef = useRef(null);
    const navigate = useNavigate();
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.3; // Set default playback speed to 0.5x
    }
  }, []); 
 
  const handleSearchClick = () => {
    navigate('/find');  // Navigate to /find when the button is clicked
  };

  return (
    <div className="bela-page-container">
      {/* Video Background */}
      <video ref={videoRef} autoPlay loop muted className="background-video">
      <source src={yourVideo} type="video/mp4" />
       
       
      </video>

      <div className="content-overlay">
        <div className="left-content">
          <h1 className="main-title">
            Bela löytää sinulle nopeasti juuri sen oikean parturin.
          </h1>
          <h2 className="sub-title">Unohda hyvä, valitse paras.</h2>
          <button className="search-button-large" onClick={handleSearchClick} >
            Aloita etsiminen
          </button>
          <p className="description">
            Ota haltuusi parhaat parturit ja kampaajat, löydä ammattilainen
            alueeltasi helposti ja nopeasti.
          </p>
        </div>
        <div className="right-content">
          <img src={frontImage} alt="Stack illustration" className="stack-image" />
        </div>
      </div>
    </div>
  );
};

export default BelaPage;
