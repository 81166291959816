import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import './tulokset.css'
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import logo from './logo_transparent.png';

import "./BelaPage.css";  // External CSS for styles
import yourVideo from './background.mp4';
import frontImage from './frontImage.webp'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import LoadingSpinner from './Loading';
import FilterComponent from './Filter';
import debounce from 'lodash.debounce';
import SelectedKampaaja from './selectedKampaaja';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const TuloksetPage = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedKampaaja, setSelectedKampaaja] = useState(null); // Track the selected kampaaja
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false); // For main category dropdown
  const filterRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isMapOpen, setMapOpen] = useState(false); // New state for map visibility
  const [mapKey, setMapKey] = useState(0);
  const [showButtons, setShowButtons] = useState(true);

 
  const toggleFilter = () => setFilterOpen(!isFilterOpen);
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const toggleMap = () => {
    console.log('isMapOpen:', isMapOpen); // Check state

    setMapOpen(!isMapOpen); 
    setMapKey(prevKey => prevKey + 1)
  }// Function to toggle map

 


  const [kampaajat, setKampaajat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [blink, setBlink] = useState(false);

  // Sample location data
  const kayttaaSijantia = true;
  const lahetettavaSijainti = { lat: 60.16985569999999, lon: 24.938379 };
  
  const postData = {
    varijapaksuus: {
      0: "Hiustesi väri",
      1: "Hiustesi paksuus"
    },
    tyyppijakunto: {
      0: "",
      1: "",
      2: ""
    },
    SubVarjays: "",
    SubHoidot: "",
    SubPidennykset: "",
    SubKemialliset: "",
    leikkauksetjakampaukset: {
      0: "",
      1: ""
    },
    sukupuoli: "",
    alue: kayttaaSijantia ? lahetettavaSijainti : [],
    kampaajaParturi: "",
    kielet: [],
    etaisyys: [0, 25],
    hinta: [0, 500],
    persoonallisuus: [],
    ekstrovertti: [],
    puhelias: [],
    varjaysVari: "",
    mielenkiinnot: [],
    paaKategoria: "",
    subKategoria: [],
  };

  const [filter, setFilter] = useState({
    hiustesiVari: postData.varijapaksuus[0] || "",     // Hiustesi väri
    hiustesiPaksuus: postData.varijapaksuus[1] || "",  // Hiustesi paksuus
    hiustyyppisi: postData.tyyppijakunto[0] || "",     // Hiustyyppisi
    hiustesiKunto: postData.tyyppijakunto[1] || "",    // Hiustesi kunto
    hiustesiPituus: postData.tyyppijakunto[2] || "",   // Hiustesi pituus
    subLeikkaukset: postData.leikkauksetjakampaukset[0] || "", // Leikkaukset (empty string if none)
    subKampaukset: postData.leikkauksetjakampaukset[1] || "",  // Kampaukset (empty string if none)
    subVarjays: postData.SubVarjays || "",            // Värjäykset (empty string if none)
    subHoidot: postData.SubHoidot || "",              // Hoidot (empty string if none)
    subPidennykset: postData.SubPidennykset || "",    // Pidennykset (empty string if none)
    subKemialliset: postData.SubKemialliset || "",    // Rakennekäsittelyt (empty string if none)
    sukupuoli: postData.sukupuoli || "",              // Sukupuoli (empty string if none)
    alue: postData.alue || "",                        // Alue (empty string if none)
    kampaajaParturi: postData.kampaajaParturi || "",  // Kampaaja/Parturi (empty string if none)
    kielet: postData.kielet || [],                    // Kielet (empty array if none)
    etaisyys: postData.etaisyys || [0, 25],           // Etaisyys (default range if none)
    hinta: postData.hinta || [0, 500],                // Hinta (default range if none)
    persoonallisuus: postData.persoonallisuus || [],  // Persoonallisuus (empty array if none)
    ekstrovertti: postData.ekstrovertti || [],        // Ekstrovertti (empty array if none)
    puhelias: postData.puhelias || [],                // Puhelias (empty array if none)
    varjaysVari: postData.varjaysVari || "",          // Värjäys Vari (empty array if none)
    mielenkiinnot: postData.mielenkiinnot || [],      // Mielenkiinnot (empty array if none)
    paaKategoria: postData.paaKategoria || "",        // Pää Kategoria (empty string if none)
    subKategoria: postData.subKategoria || []         // Sub Kategoria (empty array if none)
  });
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterOpen(false);
      }
    
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const mapFilterToPostData = () => ({
    varijapaksuus: {
      0: filter.hiustesiVari,
      1: filter.hiustesiPaksuus
    },
    tyyppijakunto: {
      0: filter.hiustyyppisi,
      1: filter.hiustesiKunto,
      2: filter.hiustesiPituus
    },
    SubVarjays: filter.subVarjays || "Värjäykset",
    SubHoidot: filter.subHoidot || "Hoidot",
    SubPidennykset: filter.subPidennykset || "Pidennykset",
    SubKemialliset: filter.subKemialliset || "Kemialliset",
    leikkauksetjakampaukset: {
      0: filter.subLeikkaukset || "Leikkaukset",
      1: filter.subKampaukset || "Kampaukset",
    },
    sukupuoli: filter.sukupuoli,
    alue: filter.alue,
    kampaajaParturi: filter.kampaajaParturi,
    kielet: filter.kielet,
    etaisyys: filter.etaisyys,
    hinta: filter.hinta,
    persoonallisuus: filter.persoonallisuus,
    ekstrovertti: filter.ekstrovertti,
    puhelias: filter.puhelias,
    varjaysVari: filter.varjaysVari,
    mielenkiinnot: filter.mielenkiinnot,
    paaKategoria: filter.paaKategoria
  });
  

  // Sample postData structure
  

  // Fetch data from the /search endpoint
  const fetchKampaajaData = useCallback(async () => {
    try {

      // Store postData in sessionStorage
      console.log(filter,"Filter")
      const postData =  mapFilterToPostData();
    
      ;
      console.log(postData, "postData")

      
      const response = await axios.post('https://pretti-207c14282d6e.herokuapp.com/search', {
        postData: postData,
      });

      if (response.status === 200) {
        const { updatedKampaajat } = response.data;

        // Fetch details for each kampaaja using the /kampaaja/info/:id endpoint
        const detailedKampaajat = await Promise.all(
          updatedKampaajat.map(async (kampaaja) => {
            const kampaajaInfo = await getKampaajaInfo(kampaaja.publicId);
            const imageUrl = await getImageUrl(kampaaja.publicId);
            return {
              ...kampaajaInfo, // Merge kampaaja info details
              url: imageUrl || 'https://via.placeholder.com/150', // Add the image URL or a placeholder
              normalizedScore: kampaaja.normalizedScore, // Include normalized score from the /search response
            };
          })
        );
        console.log(detailedKampaajat)
        setKampaajat(detailedKampaajat); // Update state with detailed kampaajat
        setLoading(false); // Stop the loading spinner

      }
    } catch (err) {
      console.error('Error fetching kampaaja data:', err);
      setError('Failed to fetch kampaaja data');
      setLoading(false); // Stop the loading spinner on error
    }
  }, [filter]);
   const videoRef = useRef(null);
    const navigate = useNavigate();
 
 
    const handleSearchClick = () => {
      window.scrollTo({
        top: 620,        // Scroll to 80 pixels from the top
        behavior: 'smooth'  // Smooth scrolling
      });
    };

    const debouncedFetchKampaajaData = useCallback(debounce(fetchKampaajaData, 1000), []);

    const handleFilterChange = useCallback((filterUpdater) => {
      setBlink(true);

      setFilter((prevFilter) => {
        const newFilter = typeof filterUpdater === 'function' ? filterUpdater(prevFilter) : filterUpdater;
        console.log('New Filter:', newFilter);
        return newFilter;
      });
    }, []);


    

    const handleKampaajaClick = async (kampaaja) => {
      try {
        // Overwrite the latest kampaaja in local storage
        localStorage.setItem('latestKampaaja', JSON.stringify(kampaaja));
    
        // Navigate using the updated kampaaja information
        await navigate(`/ammattilainen`);
        console.log(`Navigated to /ammattilainen:${kampaaja.publicId}`);
      } catch (error) {
        console.error('Error during navigation:', error);
      }
    };
  
  // Function to get kampaaja info for a specific kampaaja
  const getKampaajaInfo = async (id) => {
    try {
      
      const response = await axios.get(`https://pretti-207c14282d6e.herokuapp.com/kampaaja/info/${id}`);
      setTimeout(() => setBlink(false), 500);
      return response.data; // Return the kampaaja info details
      

    } catch (err) {
      console.error(`Error fetching kampaaja info for ID ${id}:`, err);
      setBlink(false);
      return {}; // Return an empty object if there's an error
    }
  };
 //                 {kampaaja.normalizedScore !== undefined ? `${kampaaja.normalizedScore}% Match` : 'No match data'}

  // Function to get image URL for a specific kampaaja
  const getImageUrl = async (id) => {
    try {
      const response = await axios.get(`https://pretti-207c14282d6e.herokuapp.com/kampaaja/imageId/profile/${id}`);
      return response.data.url;
    } catch (err) {
      console.error(`Error fetching image URL for ID ${id}:`, err);
      return null; // Return null if there's an error fetching the image
    }
  };

  // useEffect to load the data when the component mounts
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchKampaajaData();
    }, 1000); // Adjust the delay as needed
  
    return () => {
      clearTimeout(handler);
    };
  }, [fetchKampaajaData]);

  if (loading) return <LoadingSpinner />; // Show a loading spinner while data is being fetched
  if (error) return <div>{error}</div>; // Show error message if fetching fails


  // onClick handlers
  const handleAmmattilaiselleClick = () => {
    navigate('/parturikampaaja');
  };

  
  const handleMeistaClick = () => {
    window.location.href = 'https://kampaaja.bela.fi';
  };

  return (
    
    <div className="tulokset-container">
        <header className="header">
        <img src={logo} alt="Bela Logo" className="logo" />

        <div className="header-buttons">
          <button className="nav-button" onClick={handleAmmattilaiselleClick}>
            Ammattilaiselle
          </button>
          <button className="nav-button" onClick={handleMeistaClick}>
            Rekisteröidy
          </button>
        </div>

      </header>
      {!selectedKampaaja && (
            <div className="content-overlay">
        <div className="left-content">
      
          <h1 className="main-title">
            Arvuuttelun aikakausi on ohi. Belalta tiedät mitä saat
          </h1>
          
          <button className="search-button-large" onClick={handleSearchClick} >
            Aloita etsiminen
          </button>
         
        </div>
        <div className="right-content">
          <img src={frontImage} alt="Stack illustration" className="stack-image" />
        </div>
      </div> )}
    {/* Conditionally render content based on selectedKampaaja */}
    {!selectedKampaaja && (

      <div className="selected-filters">
  {/* Display the selected filters */}
  {filter.hiustyyppisi && (
    <span className="selected-filter">
     {filter.hiustyyppisi}
      <button onClick={() => handleFilterChange({ ...filter, hiustyyppisi: '' })}>x</button>
    </span>
  )}
  {filter.hiustesiKunto && (
    <span className="selected-filter">
      {filter.hiustesiKunto}
      <button onClick={() => handleFilterChange({ ...filter, hiustesiKunto: '' })}>x</button>
    </span>
  )}



{filter.alue && filter.alue[0] && filter.alue[0].formatted && (
  <span className="selected-filter">
    {filter.alue[0].formatted}
    <button onClick={() => handleFilterChange({ ...filter, alue: [] })}>x</button>
  </span>
)}
  {filter.hiustesiPituus && (
    <span className="selected-filter">
      {filter.hiustesiPituus}
      <button onClick={() => handleFilterChange({ ...filter, hiustesiPituus: '' })}>x</button>
    </span>
  )}
  {filter.paaKategoria && (
    <span className="selected-filter">
      {filter.paaKategoria}
      <button onClick={() => handleFilterChange({ ...filter, paaKategoria: '', subKategoria: [] })}>x</button>
    </span>
  )}
 {filter.subLeikkaukset && (
    <span className="selected-filter">
      {filter.subLeikkaukset}
      <button onClick={() => handleFilterChange({ ...filter, subLeikkaukset: '' })}>x</button>
    </span>
  )}
  {filter.subHoidot && (
    <span className="selected-filter">
      {filter.subHoidot}
      <button onClick={() => handleFilterChange({ ...filter, subHoidot: '' })}>x</button>
    </span>
  )}
  {filter.sukupuoli && (
    <span className="selected-filter">
      {filter.sukupuoli}
      <button onClick={() => handleFilterChange({ ...filter, sukupuoli: '' })}>x</button>
    </span>
  )}
  {filter.subVarjays && (
    <span className="selected-filter">
      {filter.subVarjays}
      <button onClick={() => handleFilterChange({ ...filter, subVarjays: '' })}>x</button>
    </span>
  )}
  {filter.subKampaukset && (
    <span className="selected-filter">
      {filter.subKampaukset}
      <button onClick={() => handleFilterChange({ ...filter, subKampaukset: '' })}>x</button>
    </span>
  )}

{filter.kampaajaParturi && (
    <span className="selected-filter">
      {filter.kampaajaParturi}
      <button onClick={() => handleFilterChange({ ...filter, kampaajaParturi: '' })}>x</button>
    </span>
  )}
    {filter.subKemialliset && (
    <span className="selected-filter">
      {filter.subKemialliset}
      <button onClick={() => handleFilterChange({ ...filter, subKemialliset: '' })}>x</button>
    </span>
  )}
     {filter.subPidennykset && (
    <span className="selected-filter">
      {filter.subPidennykset}
      <button onClick={() => handleFilterChange({ ...filter, subPidennykset: '' })}>x</button>
    </span>
  )}

{filter.varjaysVari && (
    <span className="selected-filter">
      {filter.varjaysVari}
      <button onClick={() => handleFilterChange({ ...filter, varjaysVari: '' })}>x</button>
    </span>
  )}

{filter.mielenkiinnot && filter.mielenkiinnot.length > 0 && (
  <div className="selected-filters">
    {filter.mielenkiinnot.map((interest, index) => (
      <span key={index} className="selected-filter">
        {interest}
        <button
          onClick={() => handleFilterChange({
            ...filter,
            mielenkiinnot: filter.mielenkiinnot.filter(item => item !== interest)
          })}
        >
          x
        </button>
      </span>
    ))}
  </div>
)}
{filter.kielet && filter.kielet.length > 0 && (
  <div className="selected-filters">
    {filter.kielet.map((interest, index) => (
      <span key={index} className="selected-filter">
        {interest}
        <button
          onClick={() => handleFilterChange({
            ...filter,
            kielet: filter.kielet.filter(item => item !== interest)
          })}
        >
          x
        </button>
      </span>
    ))}
  </div>
)}

</div>
    )}


                {!selectedKampaaja && (
                  

                  <div ref={filterRef} className={`filter-container ${isFilterOpen ? 'open' : ''}`}>

      {/* FilterComponent */}
     
      <FilterComponent filter={filter} onFilterChange={handleFilterChange} />

  </div>
                )}

<div className={`button-container-for-mobile-filter ${showButtons ? 'show' : ''}`}>

                  <button className="filter-toggle-button" onClick={toggleFilter}>
                Filters
            </button>

            <button className="map-toggle-button" onClick={toggleMap}>
      {isMapOpen ? 'Piilota' : 'Kartta'}
    </button>
          
    </div>

      <div className="tulokset-content">
    
     
<div className={`kampaajat-grid ${loading ? 'loading' : ''} ${blink ? 'blink-animation' : ''}`}>
{kampaajat.map((kampaaja) => (
                <button
                  className="kampaaja-button"
                  key={kampaaja.publicId}
                  onClick={() => handleKampaajaClick(kampaaja)} // Set selected kampaaja on click
                >
                  <div className="kampaaja-box">
                    <img
                      className="kampaaja-image"
                      src={kampaaja.url}
                      alt={kampaaja.nimi || 'Kampaaja'}
                      onError={(e) => (e.target.src = 'https://via.placeholder.com/150')}
                    />
                    <div className="kampaaja-details">
                      <h2>{kampaaja.nimi || 'Unnamed Kampaaja'}</h2>
                      <p>{kampaaja.Paikka || 'Unknown Location'}</p>
                      <p>{kampaaja.Osoite || 'No address provided'}</p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
           

            
            <MapContainer
              key={mapKey}

  center={[lahetettavaSijainti.lat, lahetettavaSijainti.lon]}
  zoom={13}
  className={`map-container ${isMapOpen ? 'open' : ''}`} // Dynamically add 'open'
>            <TileLayer
        className='tile'
        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a classname="tile" href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      />
              {kampaajat.map((kampaaja) => (
                <Marker key={kampaaja.publicId} position={[kampaaja.Coordinates.latitude, kampaaja.Coordinates.longitude]}>
                  <Popup>
                    <h3>{kampaaja.nimi || 'Unnamed Kampaaja'}</h3>
                    <p>{kampaaja.Paikka || 'Unknown Location'}</p>
                    <p>{kampaaja.Osoite || 'No address provided'}</p>
                    
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
      
        
       
      </div>
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Bela. Kaikki oikeudet pidätetään.</p>
        <p>
          Yhteystiedot: info@bela.fi |{' '}
          <a href="https://www.bela.fi" target="_blank" rel="noopener noreferrer">
            www.bela.fi
          </a>
        </p>
      </footer>
    </div>
  );
};

export default TuloksetPage;



