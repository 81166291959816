import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import React, { useEffect, useRef } from "react";
import AmmattilaisellePage from './ammattilaiselle';
import BelaPage from './Landing';
import TuloksetPage from './tulokset';
import Showkampaaja from './selectedKampaaja'
import yourVideo from './background.mp4';
function App() {
  const videoRef = useRef(null);

  useEffect(() => {
    // if (videoRef.current) {
  //    videoRef.current.playbackRate = 0.3; // Set default playback speed to 0.5x
 //   }
  }, []);


  return (
    <Router>
    <div className="App">
  
       
    
       <div className="App">
       <Routes>
       <Route path="/" element={<TuloksetPage />} />
       <Route path="/find" element={<TuloksetPage />} />
       <Route path='parturikampaaja' element= {<AmmattilaisellePage />} />
       <Route path= '/ammattilainen' element = {<Showkampaaja />} />
      </Routes>
    </div>
    </div>
    </Router>
  );
}

export default App;
