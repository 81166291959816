// LoadingSpinner.js
import React from 'react';
import './loading.css';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="ball"></div>
    <div className="ball"></div>
    <div className="ball"></div>
  </div>
);

export default LoadingSpinner;