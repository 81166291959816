import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './Pretti_edited.png';
import profileImage from './profileImage.jpg';
import connectImage from './connectImage.jpg';

import './Ammattilaiselle.css';

const AmmattilaisellePage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/'); // Navigate back to the home page
  };

  return (
    <div className="ammattilaiselle-container">
      <header className="ammattilaiselle-header">
        <img src={logo} alt="Bela Logo" className="logo" />
        <button className="back-button" onClick={handleBackClick}>
          Takaisin
        </button>
      </header>

      <div className="content">
        <h1 className="title">Miksi liittyä Belaan?</h1>
        <p className="benefits-section">
        Bela tarjoaa ammattilaisille ainutlaatuisen mahdollisuuden löytää oikeat asiakkaat ja kasvattaa 
        näkyvyyttään vaivattomasti. Tavoitteenamme on auttaa ammattilaisia rakentamaan vankka asiakaskunta,
        jonka kanssa työskentely on nautinnollista ja palkitsevaa.
        </p>

        <div className="benefits-section">
          <img src={profileImage} alt="Profile creation" className="benefits-image" />
          <h2>Varmista näkyvyys ja sitoutuneet asiakkaat</h2>
          <p>
          Kehittyneet algoritmimme yhdistävät sinut oikeiden asiakkaiden kanssa, jolloin voit
           keskittyä siihen, mitä parhaiten osaat. Bela löytää asiakkaat puolestasi, ja he palaavat 
           luoksesi, koska tietävät saavansa juuri sitä, mitä tarvitsevat.
          </p>
        </div>

        <div className="benefits-section">
          <img src={connectImage} alt="Connecting people" className="benefits-image" />
          <h2>Ilmainen ja riskitön kokeilu</h2>
          <p>
            Palvelumme on aluksi täysin ilmainen ammattilaisille. Sitoutumista ei vaadita, emmekä kysy 
            maksutietojasi. Luo profiilisi ja anna meidän hoitaa loput.
          </p>
          <p>
            Luo profiilisi:{' '}
            <a href="https://www.kampaaja.bela.fi" target="_blank" rel="noopener noreferrer">
              kampaaja.bela.fi
            </a>
          </p>
        </div>
      </div>

      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Bela. Kaikki oikeudet pidätetään.</p>
        <p>
          Yhteystiedot: info@bela.fi |{' '}
          <a href="https://www.bela.fi" target="_blank" rel="noopener noreferrer">
            www.bela.fi
          </a>
        </p>
      </footer>
    </div>
  );
};

export default AmmattilaisellePage;
